import { Link } from 'gatsby'
import React from 'react'
import { styled } from '@material-ui/core/styles'
import Container from '../components/basic/Container'
import MainLayout from '../components/MainLayout'

const CenteredContainer = styled(Container)({
  textAlign: 'center',
  marginBottom: '5rem'
})

const NotFoundPage = () => (
  <MainLayout>
    <CenteredContainer>
      <h2>Hm, hier scheint sich was geändert zu haben...</h2>
      <p>Wie wäre es hiermit?</p>
      <ul style={{display: 'inline-block', textAlign: 'left'}}>
        <li><Link to="/kanji-schreiben/">Kanji schreiben</Link></li>
        <li><Link to="/kanji-lesen/">Kanji lesen</Link></li>
      </ul>
    </CenteredContainer>
  </MainLayout>
)

export default NotFoundPage
